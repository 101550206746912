import React from 'react';
import Navigation from "../components/Navigation"
import { PageContainer } from "../components/PageContainer"
import { Container } from "../components/Container"
import { Button } from "../components/Button"
import Footer from "../elements/Footer"

const ThankYouPage = () => {

  return (
    <>
      <PageContainer>
        <Navigation />
        <Container className="mt-16 mb-16">
          <h1
            className="font-display text-5xl sm:text-7xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] ">
            Thank you for your inquiry.
          </h1>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            We will get back to you shortly.
          </p>
          <Button href="/" className="mt-8">
            Go to Home Page
          </Button>
        </Container>
      </PageContainer>
      <Footer />
    </>
  );
};

export default ThankYouPage;

export const Head = () => <title>Thank you</title>
